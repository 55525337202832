import React, { useRef, useState } from "react"
import { connect } from "react-redux"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import leadImage from "./../../images/lead/lead-image.png"
import leadImage2x from "./../../images/lead/lead-image@2x.png"
import { sendLeadForm } from "./../../actions/mailing"
import { setAlert } from "./../../actions/alert"
import { SWITCH_PROMO } from "./../../actions/actionTypes"
import PropTypes from "prop-types"
import { nl2br } from "../../utils/functions"
import { navigate } from "gatsby"

function ContactUs({
    translations,
    lang,
    sendLeadForm,
    setAlert,
    removePromo,
}) {
    const [formData, setFormData] = useState({
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        studioname: "",
        city: "",
        role: "Studioinhaber",
        clubtype: "Fitness",
        offer: "Thekenbetrieb",
    })

    const [send, setSend] = useState(false)

    const fieldErrors = {
        firstname: false,
        lastname: false,
        email: false,
        phone: false,
        studioname: false,
        city: false,
        role: false,
        clubtype: false,
        offer: false,
    }

    const [formErrors, setFormErrors] = useState(fieldErrors)

    const { firstname, lastname, email, phone, studioname, city } = formData

    const countryRef = useRef()

    const validateFields = () => {
        let success = true
        for (const [key, value] of Object.entries(formData)) {
            if (value === "") {
                success = false
                fieldErrors[key] = true
            } else if (
                key === "email" &&
                !/^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(
                    value
                )
            ) {
                success = false
                fieldErrors[key] = true
            } else {
                fieldErrors[key] = false
            }
        }
        setFormErrors(fieldErrors)
        return success
    }

    const onChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const onKeyUp = e => {
        if (send === true) {
            validateFields()
        }
    }

    const onSubmit = e => {
        e.preventDefault()
        if (validateFields()) {
            setSend(true)
            const data = { ...formData, ...{ country: countryRef.current.value } }
            sendLeadForm(data)
            if (typeof window !== "undefined") {
                window.location.href = lang === "de" ? "/contact-us/danke/" : "/en/contact-us/danke/"
            }
        } else {
            setAlert("Bitte füllen Sie alle Felder aus!", "danger")
        }
    }

    const closeContactForm = () => {
        //removePromo();
        navigate(lang === "de" ? "/" : "/en/")
    }

    return (
        <Layout>
            <SEO title={translations.contact_us_title} description={translations.contact_us_meta_description} />
            <div className="subpage lead">
                <div className="lightholder"></div>
                <section>
                    <button
                        className="contact-closer"
                        onClick={closeContactForm}
                        title={translations.contact_closer_title}
                    >
                        <span></span>
                        <span></span>
                    </button>
                    <div className="inner-wrapper">
                        <div>
                            <h1>
                                {nl2br(translations.contact_us_white_headline)}
                                <br />
                                <span className="yellow">
                                    {nl2br(translations.contact_us_yellow_headline)}
                                </span>
                            </h1>
                            <p className="t-upper bolder mt">{nl2br(translations.contact_us_subline)}</p>
                        </div>
                        <div className="image-holder">
                            <img
                                src={leadImage}
                                srcSet={`${leadImage2x} 2x`}
                                className="no-scale"
                                alt="Lead"
                            />
                        </div>
                    </div>
                    <div className="inner-wrapper">
                        <form className="form" onSubmit={e => onSubmit(e)}>
                            <div className="form-group">
                                <input
                                    type="text"
                                    autoComplete="new-password"
                                    placeholder={translations.contact_us_formfield_1}
                                    value={firstname}
                                    className={formErrors.firstname === true ? "error" : ""}
                                    name="firstname"
                                    onChange={e => onChange(e)}
                                    onKeyUp={e => onKeyUp(e)}
                                />
                                <input
                                    type="text"
                                    autoComplete="new-password"
                                    placeholder={translations.contact_us_formfield_2}
                                    value={lastname}
                                    name="lastname"
                                    className={formErrors.lastname === true ? "error" : ""}
                                    onChange={e => onChange(e)}
                                    onKeyUp={e => onKeyUp(e)}
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    autoComplete="new-password"
                                    placeholder={translations.contact_us_formfield_3}
                                    value={email}
                                    name="email"
                                    className={formErrors.email === true ? "error" : ""}
                                    onChange={e => onChange(e)}
                                    onKeyUp={e => onKeyUp(e)}
                                />
                                <input
                                    type="text"
                                    autoComplete="new-password"
                                    placeholder={translations.contact_us_formfield_4}
                                    value={phone}
                                    className={formErrors.phone === true ? "error" : ""}
                                    name="phone"
                                    onChange={e => onChange(e)}
                                    onKeyUp={e => onKeyUp(e)}
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    autoComplete="new-password"
                                    placeholder={translations.contact_us_formfield_5}
                                    value={studioname}
                                    className={formErrors.studioname === true ? "error" : ""}
                                    name="studioname"
                                    onChange={e => onChange(e)}
                                    onKeyUp={e => onKeyUp(e)}
                                />
                                <input
                                    type="text"
                                    autoComplete="new-password"
                                    placeholder={translations.contact_us_formfield_6}
                                    className={formErrors.city === true ? "error" : ""}
                                    value={city}
                                    name="city"
                                    onChange={e => onChange(e)}
                                    onKeyUp={e => onKeyUp(e)}
                                />
                                <input
                                    type="text"
                                    onChange={() => { }}
                                    value=""
                                    placeholder="Country"
                                    name="country"
                                    className="country-pd"
                                    ref={countryRef}
                                />
                            </div>

                            <div className="form-group center" style={{ marginTop: 50 }}>
                                <input
                                    type="submit"
                                    disabled={send}
                                    value={translations.contact_us_submit}
                                    className="btn"
                                />
                            </div>
                        </form>
                    </div>
                </section>
            </div>
        </Layout>
    )
}

ContactUs.propTypes = {
    sendLeadForm: PropTypes.func.isRequired,
    setAlert: PropTypes.func,
}

const mapStateToProps = state => ({
    translations: state.preferences.translations,
    lang: state.preferences.lang,
    serverCall: state.preferences.serverCall,
})

const mapDispatchToProps = dispatch => ({
    removePromo: () => {
        dispatch({ type: SWITCH_PROMO, payload: false })
    },
    sendLeadForm: formData => {
        dispatch(sendLeadForm(formData))
    },
    setAlert: (text, type) => {
        dispatch(setAlert(text, type))
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs)
